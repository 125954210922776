import { PropsWithContent } from "types";
import Image from "types/Image";
import { ThemeType } from "types/Theme";

type BannerBaseProps = PropsWithContent<{
  identifier?: string;
  heading?: string;
  text?: React.ReactNode;
  theme?: ThemeType;
  contentTheme?: ThemeType;
  backgroundImage?: Image;
  transparentMenuGap?: boolean;
}>;

export type BannerCtaProps = {
  linkText?: string;
  url?: string;
  target?: string;
};

type BannerCtaListProps = {
  ctas: BannerCtaProps[];
};

type BannerSingleCtaProps = BannerBaseProps & BannerCtaProps;
type BannerMultipleCtaProps = BannerBaseProps & BannerCtaListProps;
type BannerProps = BannerSingleCtaProps | BannerMultipleCtaProps;

export default BannerProps;

export const isV1 = (props: BannerProps): props is BannerSingleCtaProps => {
  return (props as BannerCtaProps).linkText !== undefined;
};
export const isV2 = (props: BannerProps): props is BannerMultipleCtaProps => {
  return (props as BannerMultipleCtaProps).ctas !== undefined;
};