import { IconArrow, LinkText } from "components/Banner/Banner.styled";
import Image from "components/Image";
import React, { ReactElement } from "react";
import { BREAKPOINT_NUMBER } from "style/breakpoints";
import generateSrcSet from "style/generateSrcSet";
import { ThemeProvider } from "styled-components";
import { Container, Heading, Link, Text, Wrapper } from "./Banner.styled";
import BannerProps, { BannerCtaProps, isV1, isV2 } from "./BannerProps";

const TextComponent = ({ text }: Pick<BannerProps, "text">) => {
  if (text === undefined) {
    return <></>;
  }

  if (typeof text === "string") {
    return (
      <Text dangerouslySetInnerHTML={{ __html: text }} />
    );
  }

  return (
    <Text>{text}</Text>
  );
};
const LinkItemComponent = ({ linkText, url, target }: BannerCtaProps) => {
  return (
    <Link to={url} target={target} underline="none">
      <LinkText>
        {linkText}
        <IconArrow aria-hidden="true" />
      </LinkText>
    </Link>
  );
};
const LinkComponent = (props: BannerProps) => {
  if (isV1(props) && props.linkText) {
    return <LinkItemComponent {...props} />;
  }

  if (isV2(props)) {
    return (
      <>
        {props.ctas.map((cta, index) => (
          <LinkItemComponent key={index} {...cta} />
        ))}
      </>
    );
  }

  return <></>;
};

const Banner = (props: BannerProps): ReactElement => {
  const {
    className,
    identifier,
    heading,
    text,
    backgroundImage,
    theme = "blue",
    contentTheme = "cyan",
    transparentMenuGap = false,
    contentId,
    anchorName,
  } = props;
  const hasText = !!(heading || text);
  const hasImage = !!backgroundImage;

  const getVariant = () => {
    if (hasImage) {
      if (hasText) return "full";
      return "image";
    }
    return "text";
  };

  const variant = getVariant();

  if (backgroundImage?.url) {
    backgroundImage.srcSet = generateSrcSet({
      image: backgroundImage.url,
      focalPoint: backgroundImage.focalPoint,
      content: [
        {
          width: 2560,
          breakpoint: BREAKPOINT_NUMBER.XXXXL,
        },
        {
          width: 1920,
          breakpoint: BREAKPOINT_NUMBER.XXXL,
        },
        {
          width: 1680,
          breakpoint: BREAKPOINT_NUMBER.XXL,
        },
        {
          width: 1365,
          breakpoint: BREAKPOINT_NUMBER.XL,
        },
        {
          width: 992,
          breakpoint: BREAKPOINT_NUMBER.L,
        },
        {
          width: 768,
          height: 1152, //768 * 1.5
        },
      ],
      maxWidth: backgroundImage.uploadedWidth,
      maxHeight: backgroundImage.uploadedHeight,
      format: "jpg",
    });
  }

  return (
    <ThemeProvider
      theme={{
        theme: hasImage ? "backgroundImage" : theme,
        contentTheme: contentTheme,
        variant: variant,
      }}
    >
      <Wrapper
        key={`${identifier}Wrapper`}
        id={identifier || contentId || anchorName}
        className={className}
      >
        {backgroundImage && (
          <Image
            {...backgroundImage}
            cover={true}
            darken={hasText}
            lazyload={false}
          />
        )}
        <Container
          id={`${identifier}Container`}
          key={`${identifier}Container`}
          className={className}
          $topGap={transparentMenuGap}
        >
          {heading && <Heading>{heading}</Heading>}
          <TextComponent text={text} />
          <LinkComponent {...props} />
        </Container>
      </Wrapper>
    </ThemeProvider>
  );
};

export default React.memo(Banner);
