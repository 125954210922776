import React, { ReactElement, useContext } from "react";
import { ThemeContext, ThemeProvider } from "styled-components";

import { Office28 } from "icons";
import { BREAKPOINT_NUMBER } from "style/breakpoints";
import { LabelS } from "style/components/Typography";
import generateSrcSet from "style/generateSrcSet";
import Image from "types/Image";
import { appendComponentWithNoBr } from "utils/appendComponentWithNoBr";
import ucFirst from "utils/ucFirst";

import {
  Container,
  Content,
  Description,
  Email,
  Heading,
  HeadingArrow,
  HeadingUrl,
  Initials,
  Office,
  Offices,
  Phone,
  PhoneSection,
  ProfileImage,
  ProfileLink,
} from "./Profile.styled";
import ProfileProps, { ProfileImageOrInitialsProps } from "./ProfileProps";
import Skeleton from "components/Skeleton";

const ProfileImageOrInitials = ({ image, initials }: ProfileImageOrInitialsProps): ReactElement => {
  return (
    <>{image ? <ProfileImage {...image} /> : <Initials>{initials}</Initials>}</>
  );
};
const ImageComponent = ({ loading, url, fullName, imageUrl, initials }: Pick<ProfileProps, "loading" | "url" | "fullName" | "imageUrl" | "initials">) => {
  let image: Image | undefined = undefined;
  if (imageUrl) {
    image = {
      url: imageUrl,
      decorative: false,
      alt: fullName,
      overlay: "UpLeft",
    };

    if (!imageUrl.startsWith("data:")) {
      image.srcSet = generateSrcSet({
        image: imageUrl,
        content: [
          {
            width: 154,
            height: 154,
            breakpoint: BREAKPOINT_NUMBER.XL,
          },
          {
            width: 147,
            height: 147,
            breakpoint: BREAKPOINT_NUMBER.M,
          },
          {
            width: 84,
            height: 84,
          },
        ],
        format: "jpg",
      });
    }
  }

  if (loading) {
    return (
      <Skeleton variant="rectangular">
        <ProfileImageOrInitials image={image} initials={initials} />
      </Skeleton>
    );
  }

  if (url) {
    return (
      <ProfileLink url={url} title={fullName} underline="none">
        <ProfileImageOrInitials image={image} initials={initials} />
      </ProfileLink>
    );
  }

  return (
    <ProfileImageOrInitials image={image} initials={initials} />
  );
};
const NameComponent = ({ loading, url, urlTitle, fullName }: Pick<ProfileProps, "loading" | "url" | "urlTitle" | "fullName">) => {
  if (loading) {
    return (
      <Skeleton variant="text" width="150px">
        <Heading>fullname</Heading>
      </Skeleton>
    );
  }

  if (url) {
    return (
      <HeadingUrl to={url} underline="none" title={urlTitle}>
        {appendComponentWithNoBr(
          fullName,
          <HeadingArrow icon="longArrowRight28" aria-hidden="true" />
        )}
      </HeadingUrl>
    );
  }

  return (
    <Heading>{fullName}</Heading>
  );
};
const RoleComponent = ({ loading, role, businessArea }: Pick<ProfileProps, "loading" | "role" | "businessArea">) => {
  if (loading) {
    return (
      <Skeleton variant="text" width="250px">
        <LabelS>role</LabelS>
      </Skeleton>
    );
  }

  if (role || businessArea) {
    return (
      <LabelS>
        {role && <span>{role}</span>}
        {role && businessArea && " "}
        {businessArea && <span>{businessArea}</span>}
      </LabelS>
    );
  }

  return <></>;
};
const EmailComponent = ({ loading, email }: Pick<ProfileProps, "loading" | "email">) => {
  if (loading) {
    return (
      <Skeleton variant="text" width="350px">
        <Email>email@contoso.com</Email>
      </Skeleton>
    );
  }

  if (email) {
    return (
      <Email email={email} obfuscateChildren={false}>
        <span>{email}</span>
      </Email>
    );
  }

  return <></>;
};
const PhoneComponent = ({ loading, phoneMobile, phoneFixed }: Pick<ProfileProps, "loading" | "phoneMobile" | "phoneFixed">) => {
  if (loading) {
    return (
      <Skeleton variant="text" width="350px">
        <PhoneSection>
          <Phone>phone</Phone>
        </PhoneSection>
      </Skeleton>
    );
  }

  if (phoneMobile || phoneFixed) {
    return (
      <PhoneSection>
        {phoneMobile && (
          <Phone tel={phoneMobile?.replace(/\s+/g, "")}>
            {phoneMobile}
          </Phone>
        )}
        {phoneFixed && (
          <Phone tel={phoneFixed?.replace(/\s+/g, "")}>
            {phoneFixed}
          </Phone>
        )}
      </PhoneSection>
    );
  }

  return <></>;
};
const DescriptionComponent = ({ loading, description }: Pick<ProfileProps, "loading" | "description">) => {
  if (loading || !description) {
    return <></>;
  }

  if (typeof description === "string") {
    return (
      <Description dangerouslySetInnerHTML={{ __html: description }} />
    );
  }

  return (
    <Description>{description}</Description>
  );
};
const OfficeComponent = ({ loading, offices }: Pick<ProfileProps, "loading" | "offices">) => {
  if (loading || !offices || offices.length === 0) {
    return <></>;
  }

  return (
    <Offices>
      {offices.map((office, index) => (
        <Office key={`Office${index}`}>
          <Office28 />
          {[ucFirst(office.city), office.addressRow1]
            .filter(Boolean)
            .join(", ")}
        </Office>
      ))}
    </Offices>
  );
};

const Profile = ({
  theme,
  size = "large",
  anchorName,
  className,
  ...props
}: ProfileProps): ReactElement => {
  const exitingTheme = useContext(ThemeContext) || {};

  return (
    <ThemeProvider theme={{ theme: theme || exitingTheme["theme"], size: size }}>
      <Container id={anchorName} className={className}>
        <ImageComponent {...props} />
        <Content>
          <NameComponent {...props} />
          <RoleComponent {...props} />
          <EmailComponent {...props} />
          <PhoneComponent {...props} />
          <DescriptionComponent {...props} />
          <OfficeComponent {...props} />
        </Content>
      </Container>
    </ThemeProvider>
  );
};

export default React.memo(Profile);
